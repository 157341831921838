import React from "react";

function City() {
  return (
    <>
      <option value="" disabled selected>
        ---- Select your city ----
      </option>
      <option value="">Others</option>
      <option value="Ambasamudram">Ambasamudram</option>
      <option value="Anamali">Anamali</option>
      <option value="Arakandanallur">Arakandanallur</option>
      <option value="Arantangi">Arantangi</option>
      <option value="Aravakurichi">Aravakurichi</option>
      <option value="Ariyalur">Ariyalur</option>
      <option value="Arakonam">Arakonam</option>
      <option value="Arani">Arani</option>
      <option value="Aruppukottai">Aruppukottai</option>
      <option value="Attur">Attur</option>
      <option value="Avanashi">Avanashi</option>
      <option value="Bhavani">Bhavani</option>
      <option value="Chengalpattu">Chengalpattu</option>
      <option value="Chengam">Chengam</option>
      <option value="Chennai">Chennai</option>
      <option value="Chidambaram">Chidambaram</option>
      <option value="Chingleput">Chingleput</option>
      <option value="Coimbatore">Coimbatore</option>
      <option value="Cuddalore">Cuddalore</option>
      <option value="Cumbum">Cumbum</option>
      <option value="Denkanikoitah">Denkanikoitah</option>
      <option value="Devakottai">Devakottai</option>
      <option value="Dharampuram">Dharampuram</option>
      <option value="Dharmapuri">Dharmapuri</option>
      <option value="Dindigul">Dindigul</option>
      <option value="Erode">Erode</option>
      <option value="Gingee">Gingee</option>
      <option value="Gobichettipalayam">Gobichettipalayam</option>
      <option value="Gudalur">Gudalur</option>
      <option value="Gudiyatham">Gudiyatham</option>
      <option value="Harur">Harur</option>
      <option value="Hosur">Hosur</option>
      <option value="Jayamkondan">Jayamkondan</option>
      <option value="Kallkurichi">Kallkurichi</option>
      <option value="Kanchipuram">Kanchipuram</option>
      <option value="Kangayam">Kangayam</option>
      <option value="Kanyakumari">Kanyakumari</option>
      <option value="Karaikal">Karaikal</option>
      <option value="Karaikudi">Karaikudi</option>
      <option value="karur">karur</option>
      <option value="Keeranur">Keeranur</option>
      <option value="Kodaikanal">Kodaikanal</option>
      <option value="Kodumudi">Kodumudi</option>
      <option value="Kotagiri">Kotagiri</option>
      <option value="Kovilpatti">Kovilpatti</option>
      <option value="Krishnagiri">Krishnagiri</option>
      <option value="Kulithalai">Kulithalai</option>
      <option value="Kumbakonam">Kumbakonam</option>
      <option value="kutrallam">kutrallam</option>
      <option value="Kuzhithurai">Kuzhithurai</option>
      <option value="Madurai">Madurai</option>
      <option value="Madurantgam">Madurantgam</option>
      <option value="Manamadurai">Manamadurai</option>
      <option value="Manaparai">Manaparai</option>
      <option value="Mannargudi">Mannargudi</option>
      <option value="Mayiladuthurai">Mayiladuthurai</option>
      <option value="Mayiladutjurai">Mayiladutjurai</option>
      <option value="Mettupalayam">Mettupalayam</option>
      <option value="Metturdam">Metturdam</option>
      <option value="Mudukulathur">Mudukulathur</option>
      <option value="Mulanur">Mulanur</option>
      <option value="Musiri">Musiri</option>
      <option value="Nagapattinam">Nagapattinam</option>
      <option value="Nagarcoil">Nagarcoil</option>
      <option value="Namakkal">Namakkal</option>
      <option value="Nanguneri">Nanguneri</option>
      <option value="Natham">Natham</option>
      <option value="Neyveli">Neyveli</option>
      <option value="Nilgiris">Nilgiris</option>
      <option value="Oddanchatram">Oddanchatram</option>
      <option value="Omalpur">Omalpur</option>
      <option value="Ootacamund">Ootacamund</option>
      <option value="Ooty">Ooty</option>
      <option value="Orathanad">Orathanad</option>
      <option value="Palacode">Palacode</option>
      <option value="Palani">Palani</option>
      <option value="Palladum">Palladum</option>
      <option value="Papanasam">Papanasam</option>
      <option value="Paramakudi">Paramakudi</option>
      <option value="Pattukottai">Pattukottai</option>
      <option value="Perambalur">Perambalur</option>
      <option value="Perundurai">Perundurai</option>
      <option value="Pollachi">Pollachi</option>
      <option value="Polur">Polur</option>
      <option value="Pondicherry">Pondicherry</option>
      <option value="Ponnamaravathi">Ponnamaravathi</option>
      <option value="Ponneri">Ponneri</option>
      <option value="Pudukkottai">Pudukkottai</option>
      <option value="Rajapalayam">Rajapalayam</option>
      <option value="Ramanathapuram">Ramanathapuram</option>
      <option value="Rameshwaram">Rameshwaram</option>
      <option value="Ranipet">Ranipet</option>
      <option value="Rasipuram">Rasipuram</option>
      <option value="Salem">Salem</option>
      <option value="Sankagiri">Sankagiri</option>
      <option value="Sankaran">Sankaran</option>
      <option value="Sathiyamangalam">Sathiyamangalam</option>
      <option value="Sivaganga">Sivaganga</option>
      <option value="Sivakasi">Sivakasi</option>
      <option value="Sriperumpudur">Sriperumpudur</option>
      <option value="Srivaikundam">Srivaikundam</option>
      <option value="Tenkasi">Tenkasi</option>
      <option value="Thanjavur">Thanjavur</option>
      <option value="Theni">Theni</option>
      <option value="Thirumanglam">Thirumanglam</option>
      <option value="Thiruraipoondi">Thiruraipoondi</option>
      <option value="Thoothukudi">Thoothukudi</option>
      <option value="Thuraiyure">Thuraiyure</option>
      <option value="Tindivanam">Tindivanam</option>
      <option value="Tiruchendur">Tiruchendur</option>
      <option value="Tiruchengode">Tiruchengode</option>
      <option value="Tiruchirappalli">Tiruchirappalli</option>
      <option value="Tirunelvelli">Tirunelvelli</option>
      <option value="Tirupathur">Tirupathur</option>
      <option value="Tirupur">Tirupur</option>
      <option value="Tiruttani">Tiruttani</option>
      <option value="Tiruvallur">Tiruvallur</option>
      <option value="Tiruvannamalai">Tiruvannamalai</option>
      <option value="Tiruvarur">Tiruvarur</option>
      <option value="Tiruvellore">Tiruvellore</option>
      <option value="Tiruvettipuram">Tiruvettipuram</option>
      <option value="Trichy">Trichy</option>
      <option value="Tuticorin">Tuticorin</option>
      <option value="Udumalpet">Udumalpet</option>
      <option value="Ulundurpet">Ulundurpet</option>
      <option value="Usiliampatti">Usiliampatti</option>
      <option value="Uthangarai">Uthangarai</option>
      <option value="Valapady">Valapady</option>
      <option value="Valliyoor">Valliyoor</option>
      <option value="Vaniyambadi">Vaniyambadi</option>
      <option value="Vathalagundu">Vathalagundu</option>
      <option value="Vedasandur">Vedasandur</option>
      <option value="Vellore">Vellore</option>
      <option value="Vilathikulam">Vilathikulam</option>
      <option value="Villupuram">Villupuram</option>
      <option value="Virudhachalam">Virudhachalam</option>
      <option value="Virudhunagar">Virudhunagar</option>
      <option value="Wandiwash">Wandiwash</option>
      <option value="Yercaud">Yercaud</option>
    </>
  );
}

export default City;
